/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

1.  Global Styles
2.  Layout
3.  Special Templates
4.  Site Header
  4.1 Home header
  4.2 Archive header
5.  Site Navigation
6.  Post Feed
7.  Single Post
  7.1. Post Byline
  7.2. Members Subscribe Form
  7.4. Related Posts
  7.5. Koenig Styles
8.  Author Template
9.  Error Template
11. Site Footer
12. Dark Mode

*/

/* 1. Global - Set up the things
/* ---------------------------------------------------------- */

/* Import CSS reset and base styles */
@import "global.css";
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');

:root {

    /* Colours */
    --color-green: #29a03e;
    --color-yellow: #fecd35;
    --color-red: #f05230;
    --color-darkgrey: #15171A;
    --color-midgrey: #738a94;
    --color-lightgrey: #c5d2d9;
    --color-wash: #e5eff5;
    --color-darkmode: #151719;
    --fortext-ghost-accent-color: #000;

    /*
    An accent color is also set by Ghost itself in
    Ghost Admin > Settings > Brand

    --ghost-accent-color: {value};

    You can use this variale throughout your styles
     */

    /* Fonts */
    --font-sans-serif: PT Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    --font-serif: PT Serif,Georgia, Times, serif;
    --font-mono: Menlo, Courier, monospace;
    --font-title:  PT Serif;
}


/* 2. Layout - Page building blocks
/* ---------------------------------------------------------- */

body {
    font-family: var(--font-sans-serif);
    transition: linear .4s;
    color: rgba(0,0,0,0.95);
    overflow-x:hidden;
}
.button-basic, .button-basic:hover,.sidebar-tag, .sidebar0tag:hover, article, .gh-head, .site-header-content, .home-template .gh-head, .home-template .gh-head.has-cover,  .post-feed, #footer, .the-content, .inner, .grid-w-sidebar {
    transition: linear .4s;
}
.viewport {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    transition: linear .4s;
}

.viewport > main {
    flex-grow: 1;
}

main {
    transition: linear .4s;
    background:#fff;
    position:relative;
    z-index:1;
}

/* Full width page blocks */
.outer {
    position: relative;
    padding: 0 0vmin;
}

/* Centered content container blocks */
.inner {
    margin-left:auto;
    margin-right:auto;
    max-width: 1200px;
    width: 100%;
}


/* 4. Site Header
/* ---------------------------------------------------------- */

.site-header {
    position: relative;
    color: #fff;
    background: var(--ghost-accent-color);
}
.site-header-cover {
    object-fit: cover;
    width: 400px;
    height:400px;
    text-align:right;
    margin-left:auto;
}

.site-header-content {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items:center;
}
.hero {    
    position: relative;
    display: flex;
    justify-content:space-between;
    align-items:center;
}
.hero-text {
    width:750px;
    max-width:90%;
}
.site-title {
    z-index: 10;
    margin: 0;
    padding: 0;
    font-size: 3.4em;
    font-weight: 600;
    line-height: 1.1;
}
.site-logo {
    max-height: 55px;
}

.site-header-content p {
    z-index: 10;
    max-width: 600px;
    line-height: 1.2em;
    opacity: 0.8;
}

@media (max-width: 600px) {
    .site-header-content p {
        max-width: 80vmin;
        font-size: 1.8rem;
    }
}

/* 4.1 Home header
/* ---------------------------------------------------------- */

.site-home-header {
    position: relative;
    z-index: 1000;
    overflow: hidden;
}

.site-header-content {
    padding: 6vmin 4vmin 6vmin;
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--fortext-ghost-accent-color);
    background: var(--ghost-accent-color);
    border-bottom:1px solid #222;
}



/* 5. Site Navigation
/* ---------------------------------------------------------- */

.gh-head {
    padding: 1.5vmin 4vmin;
    font-size: 1.6rem;
    line-height: 1.3em;
    color: var(--fortext-ghost-accent-color);
    transition: linear .4s;    
    border-bottom: solid 1px rgba(41, 41, 41, 1);
    z-index: 9999;
}
/*Some tweaks, related to nav more or less*/
@media (min-width: 900px) {
    .gh-head.sticky, .home-template .gh-head.sticky {
        position:sticky;
        position:-webkit-sticky;
        background:#fff !important;
        top:0;
        width:100%;
        z-index:9999;
    }  
    .sticky-top100 {
        position: sticky;
        top: 100px;
    }
    .grid-w-sidebar {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 8rem;
    }
    .grid-w-sidebar .the-sidebar {
        grid-row: 2;
        grid-column: 2;
    
    }
    .grid-w-sidebar .the-content {
        grid-row: 2;
        grid-column: 1;
    }
}
.gh-head a {
    color: inherit;
    text-decoration: none;
}

.gh-head-inner {
    display: grid;
    grid-gap: 2.5vmin;
    grid-template-columns: auto auto 1fr;
    grid-auto-flow: row dense;
}
.post-template  .gh-head  {
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
    border-bottom:1px solid transparent;
}
/*Animations*/
.animated {
    -webkit-animation-duration: .4s;
    animation-duration: .4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
 }
 
 @-webkit-keyframes fadeInDown {
    0% {
       opacity: 0;
       -webkit-transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       -webkit-transform: translateY(0);
    }
 }
 
 @keyframes fadeInDown {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }
 
 .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
 }

/* Brand
/* ---------------------------------------------------------- */

.gh-head-brand {
    display: flex;
    align-items: center;
    height: 40px;
    max-width: 200px;
    text-align: center;
    word-break: break-all;
}

.gh-head-logo {
    display: block;
    padding: 10px 0;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 1.2em;
}

.gh-head-logo img {
    max-height: 34px;
}


/* Primary Navigation
/* ---------------------------------------------------------- */

.gh-head-menu {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.gh-head-menu .nav {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.gh-head-menu .nav li {
    margin: 0 2.5vmin 0 0;
    padding: 0;
}

.gh-head-menu .nav a {
    display: inline-block;
    padding: 5px 0;
    opacity: 0.8
}

.gh-head-menu .nav a:hover {
    opacity: 1;
}


/* Secondary Navigation
/* ---------------------------------------------------------- */

.gh-head-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    text-align: right;
}

.gh-head-actions-list {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.gh-head-actions-list a:not([class]) {
    display: inline-block;
    margin: 0 0 0 1.5vmin;
    padding: 5px 0;
}

.gh-social {
    margin: 0 1.5vmin 0 0;
}

.gh-social a {
    opacity: 0.8
}

.gh-social a + a {
    margin-left: 0.8rem;
}

.gh-social a:hover {
    opacity: 1;
}

.gh-social svg {
    height: 22px;
    width: 22px;
    fill: var(--fortext-ghost-accent-color);
}

.gh-social-facebook svg {
    height: 20px;
    width: 20px;
}

a.gh-head-button {
    display: block;
    padding: 11px 15px;
    color: #fff;
    font-weight: 500;
    letter-spacing: -0.015em;
    font-size: 1.5rem;
    line-height: 1em;
    background: var(--fortext-ghost-accent-color);
    border-radius: 30px;
}


/* Mobile Menu Trigger
/* ---------------------------------------------------------- */

.gh-burger {
    position: relative;
    display: none;
    cursor: pointer;
}

.gh-burger-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 33px;
}

.gh-burger-inner {
    width: 100%;
    height: 100%;
}

.gh-burger-box::before {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    height: 1px;
    background: currentcolor;
    transition: transform 300ms cubic-bezier(.2,.6,.3,1), width 300ms cubic-bezier(.2,.6,.3,1);
    will-change: transform, width;
}

.gh-burger-inner::before,
.gh-burger-inner::after {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    height: 1px;
    background: currentcolor;
    transition: transform 250ms cubic-bezier(.2,.7,.3,1), width 250ms cubic-bezier(.2,.7,.3,1);
    will-change: transform, width;
}

.gh-burger-inner::before {
    transform: translatey(-6px);
}
.gh-burger-inner::after {
    transform: translatey(6px);
}

body:not(.gh-head-open) .gh-burger:hover .gh-burger-inner::before {
    transform: translatey(-8px);
}
body:not(.gh-head-open) .gh-burger:hover .gh-burger-inner::after {
    transform: translatey(8px);
}

.gh-head-open .gh-burger-box::before {
    width: 0;
    transform: translatex(19px);
    transition: transform 200ms cubic-bezier(.2,.7,.3,1), width 200ms cubic-bezier(.2,.7,.3,1);
}

.gh-head-open .gh-burger-inner::before {
    width: 26px;
    transform: translatex(6px) rotate(135deg);
}

.gh-head-open .gh-burger-inner::after {
    width: 26px;
    transform: translatex(6px) rotate(-135deg);
}


/* Mobile Menu and other tweaks accompanied
/* ---------------------------------------------------------- */
/* IDs needed to ensure sufficient specificity */

@media (max-width: 900px) {
    .post-card-featured {
        margin-bottom:4rem;
    }    
    .author-profile-content .d-flex-center {
        text-align:center;
        margin-left:auto;
        margin-right:auto;
        justify-content:center;
    }
    #progress {
        top:0px !important;
    }
    .gh-burger {
        display: inline-block;
    }
    #gh-head {
        transition: all 0.4s ease-out;
        overflow: hidden;
    }
    #gh-head .gh-head-inner {
        height: 100%;
        grid-template-columns: 1fr;
        padding: 0;
    }
    #gh-head .gh-head-brand {
        position: relative;
        z-index: 10;
        grid-column-start: auto;
        max-width: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        user-select: none;
    }
    #gh-head .gh-head-menu {
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0 0 10vh 0;
        font-weight: 300;
        font-size: 3.6rem;
        line-height: 1.1em;
    }
    #gh-head .gh-head-menu .nav li {
        margin: 5px 0;
    }
    #gh-head .gh-head-menu .nav a {
        padding: 8px 0;
    }
    #gh-head .gh-head-menu .nav {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #gh-head .gh-head-actions {
        padding: 20px 0;
        justify-content: center;
        text-align: left;
    }
    #gh-head .gh-head-actions a {
        margin: 0 10px;
    }
    /* Hide collapsed content */
    #gh-head .gh-head-actions,
    #gh-head .gh-head-menu {
        display: none;
    }
    /* Open the menu */
    .gh-head-open {
        overflow: hidden;
        height: 100vh;
    }
    .gh-head-open #gh-head {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3999999;
        overflow-y: scroll;
        background: var(--ghost-accent-color);
    }
    .gh-head-open #gh-head .gh-head-inner {
        grid-template-rows: auto 1fr auto;
    }
    .gh-head-open #gh-head .gh-head-actions,
    .gh-head-open #gh-head .gh-head-menu {
        display: flex;
    }
}

@media (max-width: 600px) {
    #gh-head .gh-head-menu {
        font-size: 6vmin;
    }
}

.home-template .gh-head.has-cover {
    background: var(--ghost-accent-color);
}

.home-template.gh-head-open .gh-head {
    background: var(--ghost-accent-color);
}

/* 6. Post Feed
/* ---------------------------------------------------------- */

.post-feed {
    position: relative;
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 4rem 0;
}
.post-feed-no-grid {
    display:block;
    padding:0;
}
@media (max-width: 1000px) {
    .post-feed {
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 700px) {
    .post-feed {
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }
}


.post-card {
    position: relative;
    flex: 1 1 301px;
    display: flex;
    flex-direction: column;
    min-height: 220px;
    background-size: cover;
    word-break: break-word;
}

.post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
}

.post-card-image {
    width: 100%;
    height: 200px;
    background: var(--color-lightgrey) no-repeat center center;
    object-fit: cover;
}

.post-card-content-link {
    position: relative;
    display: block;
    color: var(--color-darkgrey);
}

.post-card-content-link:hover {
    text-decoration: none;
}

.post-card-header {
    margin: 20px 0 0;
}

.post-feed .no-image .post-card-content-link {
    padding: 0;
}

.no-image .post-card-header {
    margin-top: 0;
}

.post-card-title {
    margin: .4em 0 0.4em;
    font-size: 2.4rem;
    transition: all .4s;
    line-height: 1.33;
}
a .post-card-title:hover {
    text-decoration: underline;
    transition: all .4s; 
}
.no-image .post-card-title {
    margin-top: 0;
}

.post-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.post-card-excerpt {
    max-width: 56em;
    color: color-mod(var(--color-midgrey) l(-8%));
}

.post-card-excerpt p {
    margin-bottom: 1em;
    display: -webkit-box;
    overflow-y: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
    font-size:1.1em;
}

.post-card-meta {
    display: flex;
    align-items: center;
    padding: 0;
}

.author-profile-image,
.avatar-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.post-card-meta .profile-image-wrapper,
.post-card-meta .avatar-wrapper {
    position: relative;
}

.author-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 4px;
    padding: 0;
    list-style: none;
}
.author-list a {
    font-weight:600;
}
.author-list-item {
    position: relative;
    flex-shrink: 0;
    margin: 0 5px 0 0;
    padding: 0;
}
.author-list-item:last-child {
    margin:0;
}
.static-avatar {
    display: block;
    overflow: hidden;
    margin: 0 6px 0 -6px;
    width: 34px;
    height: 34px;
    border-radius:50%;
    padding:3px;
    border:2px solid var(--color-lightgrey);
}
.static-avatar img {
    border-radius:50%;
}
.post-card-byline-content {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 0px;
    color: rgba(117, 117, 117, 1);
    font-size: 16px;
    line-height: 1.2em;
    font-weight: 400;
}
.post-card-byline-date {
    font-size: 15px;
    line-height: 1.2em;
    color: rgba(117, 117, 117, 1);
}
.post-card-byline-date .bull {
    display: inline-block;
    margin: 0 2px;
    opacity: 0.6;
}
.post-card-boxed .post-card-title {
    font-family: var(--font-serif);
    font-weight:500;
    margin: .6em 0;
}
.post-card-boxed .static-avatar {
    width:40px;
    height:40px;
    border-radius:50%;
    padding:3px;
    border:2px solid var(--color-lightgrey);
}
.post-card-boxed .static-avatar img {
    border-radius:50%;
}
.post-card-boxed .post-card-excerpt p {
    display:none;
}
.single-author-byline {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    color: color-mod(var(--color-midgrey) l(-10%));
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 500;
}

.single-author {
    display: flex;
    align-items: center;
}

.single-author .static-avatar {
    margin-left: -2px;
}

.single-author-name {
    display: inline-block;
}

.post-feed-featured {
    margin-top:7rem;  
    position:relative;
}
.post-feed-featured h6 {
    margin: 0;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: .04em;
    font-weight:700;
}
.post-card-featured .post-card-title {
    font-size:2.2rem;
    line-height: 1.4;
}
.post-card-featured .post-card-header {
    margin:0;
}
.post-card-featured  {
    min-height: auto;
    position:relative;
    margin-left: 3.8rem;
}
.post-feed-featured .post-feed {
    margin-left: 1.5em;
    counter-reset: li;
    counter-increment: li;
    position: relative;
    grid-gap: 5rem;
    padding: 4rem 0;
}

@media (min-width: 767px) {
    .post-feed-featured-list { 
        position: relative;
        display: grid;
        grid-gap: 4rem;
        padding: 4rem 0;
        grid-template-columns: 1fr 1fr;
    }
}
@media (min-width: 1024px) {
    .post-feed-featured-list {  
        grid-template-columns: 1fr 1fr 1fr;
    }
}


.grid-w-sidebar .post-card {
    margin-bottom: 6rem;
}
.grid-w-sidebar .wrap-post-card-image {
    width:40%;
    padding-left:5rem;
}
.grid-w-sidebar .post-card-image {
    width:100%;
    height:180px;
    object-position: top;
}
.sidebar-tag {
    padding:8px 20px;
    border:1px solid rgba(230, 230, 230, 1);
    border-radius:4px;
    display: inline-block;
    margin-bottom:7px;
    margin-right:7px;
}
.sidebar-tag:hover {
    border:1px solid;
}
.grid-w-sidebar .widget-title {
    margin: 0;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: .04em;
    font-weight: 700;
    margin-bottom:1.5rem;
    line-height: 1.6;
}
.widget {
    margin-bottom: 4rem;
}
.w-60p {
    width:60%;
}
.basic-button {
    display: inline-block;
    padding: 8px 20px;
    color: #fff;
    font-weight: 500;
    background: #29a03e;
    border-radius: 5px;
    border-radius:40px;
    border:2px solid transparent;
}
.basic-button:hover {
    background:transparent;
    color: #29a03e;
    border:2px solid;
}
.widget .basic-button {
    margin-top:1.5rem;
}



/* Special Styling for home page grid (below):

The first post in the list is styled to be bigger than the others and take over
the full width of the grid to give it more emphasis. Wrapped in a media query to
make sure this only happens on large viewports / desktop-ish devices.

 */

@media (min-width: 1001px) {
    .post-card-large {
        grid-column: 1 / span 3;
        display: grid;
        grid-gap: 4vmin;
        grid-template-columns: 1fr 1fr 1fr;
        min-height: 280px;
        border-top: 0;
    }

    .post-card-large:not(.no-image) .post-card-header {
        margin-top: 0;
    }

    .post-card-large .post-card-image-link {
        position: relative;
        grid-column: 1 / span 2;
        margin-bottom: 0;
        min-height: 380px;
    }

    .post-card-large .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .post-card-large .post-card-content {
        justify-content: center;
    }

    .post-card-large .post-card-title {
        margin-top: 0;
        font-size: 5.2rem;
        line-height:1.1;
        font-family: var(--font-serif);
        font-weight:500;
    }

    .post-card-large .post-card-excerpt p {
        margin-bottom: 1.5em;
        font-size: 1.7rem;
        line-height: 1.55em;
        -webkit-line-clamp: 8;
    }
}


@media (max-width: 500px) {
    .post-card-title {
        font-size: 1.9rem;
    }

    .post-card-excerpt {
        font-size: 1.6rem;
    }
}


/* 7. Single Post
/* ---------------------------------------------------------- */

.article {
    padding: 8vmin 0;
    word-break: break-word;
}

.article-header {
    padding: 0 0 6vmin 0;
}

.article-tag {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0.5rem;
    color: var(--color-midgrey);
    font-size: 1.4rem;
    line-height: 1.4em;
    letter-spacing: 0.02em;
    font-weight: 600;
    text-transform: uppercase;
}

.article-tag a {
    color:inherit;
}

.article-title {
    color: color-mod(var(--color-darkgrey) l(-5%));
}

.article-excerpt {
    margin: 0 0 1rem;
    font-size: 2rem;
    line-height: 1.4em;
    opacity: 0.6;
}

.gh-canvas .article-image {
    grid-column: wide-start / wide-end;
    width: 100%;
    margin: 6vmin 0 0;
}

.gh-canvas .article-image img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media (max-width: 600px) {
    .article-excerpt {
        font-size: 1.8rem;
    }
}

/* -------- */

/* Content grid
/* ---------------------------------------------------------- */

/* Canvas creates a multi-column, centered grid which the post
is laid out on top of. Canvas just defines the grid, we don't
use it for applying any other styles. */

.gh-canvas {
    display: grid;
    grid-template-columns:
        [full-start]
        minmax(4vmin, auto)
            [wide-start]
            minmax(auto, 240px)
                [main-start]
                    min(720px, calc(100% - 8vw))
                [main-end]
            minmax(auto, 240px)
            [wide-end]
        minmax(4vmin, auto)
        [full-end]
    ;
}

.gh-canvas > * {
    grid-column: main-start / main-end;
}

.kg-width-wide {
    grid-column: wide-start / wide-end;
}

.kg-width-full {
    grid-column: full-start / full-end;
}

.kg-width-full img {
    width: 100%;
}


/* Content
/* ---------------------------------------------------------- */

/* Content refers to styling all page and post content that is
created within the Ghost editor. The main content handles
headings, text, images and lists. We deal with cards lower down. */

/* Default vertical spacing */
.gh-content > * + * {
    margin-top: 4vmin;
    margin-bottom: 0;
}

/* [id] represents all headings h1-h6, reset all margins */
.gh-content > [id] {
    margin: 0;
    color: var(--color-darkgrey);
}

/* Add back a top margin to all headings, unless a heading
is the very first element in the post content */
.gh-content > [id]:not(:first-child) {
    margin: 2em 0 0;
}

/* Add a small margin between a heading and anything after it */
.gh-content > [id] + * {
    margin-top: 1.5rem !important;
}

/* A larger margin before/after HRs and blockquotes */
.gh-content > hr,
.gh-content > blockquote {
    position: relative;
    margin-top: 6vmin;
}
.gh-content > hr + *,
.gh-content > blockquote + * {
    margin-top: 6vmin !important;
}

/* Now the content typography styles */
.gh-content a {
    color: inherit;
    text-decoration: underline;
    word-break: break-word;
}
.gh-content a:hover {
    opacity:.8;
}
.gh-content > blockquote,
.gh-content > ol,
.gh-content > ul,
.gh-content > dl,
.gh-content > p {
    font-family: var(--font-serif);
    font-weight: 400;
    font-size: 2.02rem;
    line-height: 1.6;
}

.gh-content > ul,
.gh-content > ol,
.gh-content > dl {
    padding-left: 1.9em;
}

.gh-content > blockquote {
    position: relative;
    font-style: italic;
    padding: 0;
    margin-left:4rem;
}

.gh-content > blockquote::before {
    content: "";
    position: absolute;
    left: -1.5em;
    top: 0;
    bottom: 0;
    width: 0.3rem;
    background: var(--ghost-accent-color);
}

.gh-content :not(pre) > code {
    vertical-align: middle;
    padding: 0.15em 0.4em 0.15em;
    border: #e1eaef 1px solid;
    font-weight: 400 !important;
    font-size: 0.9em;
    line-height: 1em;
    color: #15171A;
    background: #f0f6f9;
    border-radius: 0.25em;
}

.gh-content pre {
    overflow: auto;
    padding: 16px 20px;
    color: var(--color-wash);
    font-size: 1.4rem;
    line-height: 1.5em;
    background: var(--color-darkgrey);
    border-radius: 5px;
    box-shadow: 0 2px 6px -2px rgba(0,0,0,.1), 0 0 1px rgba(0,0,0,.4);
}

@media (max-width: 650px) {
    .gh-content blockquote,
    .gh-content ol,
    .gh-content ul,
    .gh-content dl,
    .gh-content p {
        font-size: 1.8rem;
    }

    .gh-content blockquote::before {
        left: -4vmin;
    }
}


/* Cards
/* ---------------------------------------------------------- */

/* Cards are dynamic blocks of content which appear within Ghost
posts, for example: embedded videos, tweets, galleries, or
specially styled bookmark links. We add extra styling here to
make sure they look good, and are given a bit of extra spacing. */

/* Add extra margin before/after any cards,
except for when immediately preceeded by a heading */
.gh-content :not(.kg-card):not([id]) + .kg-card {
    margin-top: 6vmin;
    margin-bottom: 0;
}
.gh-content .kg-card + :not(.kg-card) {
    margin-top: 6vmin;
    margin-bottom: 0;
}

/* This keeps small embeds centered */
.kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* This keeps small iamges centered */
.kg-image-card img {
    margin: auto;
}


/* Captions */
.kg-card figcaption {
    padding: 1.5rem 1.5rem 0;
    text-align: center;
    color: rgba(0,0,0,0.5);
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 1.4em;
}
.kg-card figcaption strong {
    color: rgba(0,0,0,0.8);
}


/* Highly specific styles for traditional Instagram embeds */
iframe.instagram-media {
    margin-top: 6vmin !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 0 !important;
}

iframe.instagram-media + script + :not([id]) {
    margin-top: 6vmin;
}


/* Galleries
/* ---------------------------------------------------------- */

/* When there galleries are mixed with images, reduce margin
between them, so it looks like 1 big gallery */
.kg-image-card + .kg-gallery-card,
.kg-gallery-card + .kg-image-card,
.kg-gallery-card + .kg-gallery-card {
    margin-top: 0.75em;
}

.kg-image-card.kg-card-hascaption + .kg-gallery-card,
.kg-gallery-card.kg-card-hascaption + .kg-image-card,
.kg-gallery-card.kg-card-hascaption + .kg-gallery-card {
    margin-top: 1.75em;
}

.kg-gallery-container {
    position: relative;
}

.kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
}


/* Bookmark Cards
/* ---------------------------------------------------------- */

/* These are styled links with structured data, similar to a
Twitter card. These styles roughly match what you see in the
Ghost editor. */

.kg-bookmark-card,
.kg-bookmark-publisher {
    position: relative;
    width: 100%;
}

.kg-bookmark-container,
.kg-bookmark-container:hover {
    display: flex;
    color: currentColor;
    font-family: var(--font-sans-serif);
    text-decoration: none !important;
    background: rgba(255,255,255,0.6);
    border-radius: 5px;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.4);
    overflow: hidden;
}

.kg-bookmark-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
}

.kg-bookmark-title {
    font-size: 1.5rem;
    line-height: 1.4em;
    font-weight: 600;
    color: #15171A;
}

.kg-bookmark-description {
    display: -webkit-box;
    font-size: 1.4rem;
    line-height: 1.5em;
    margin-top: 3px;
    color: #626d79;
    font-weight: 400;
    max-height: 44px;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.kg-bookmark-metadata {
    display: flex;
    align-items: center;
    margin-top: 22px;
    width: 100%;
    color: #394047;
    font-size: 1.4rem;
    font-weight: 500;
}

.kg-bookmark-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.kg-bookmark-author,
.kg-bookmark-publisher {
    display: inline;
}

.kg-bookmark-publisher {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
    white-space: nowrap;
    display: block;
    line-height: 1.65em;
}

.kg-bookmark-metadata > span:nth-of-type(2) {
    color: #626d79;
    font-weight: 400;
}

.kg-bookmark-metadata > span:nth-of-type(2):before {
    content: "•";
    color: #394047;
    margin: 0 6px;
}

.kg-bookmark-thumbnail {
    position: relative;
    flex-grow: 1;
    min-width: 33%;
}

.kg-bookmark-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 4px 4px 0;
}


/* Card captions
/* ---------------------------------------------------------- */

.kg-width-full.kg-card-hascaption {
    display: grid;
    grid-template-columns: inherit;
}

.kg-width-wide.kg-card-hascaption img {
    grid-column: wide-start / wide-end;
}
.kg-width-full.kg-card-hascaption img {
    grid-column: 1 / -1;
}

.kg-width-full.kg-card-hascaption figcaption {
    grid-column: main-start / main-end;
}

.article-comments {
    margin: 6vmin 0 0 0;
}

/* -----old------ */

.footnotes-sep {
    margin-bottom: 30px;
}

.footnotes {
    font-size: 1.5rem;
}

.footnotes p {
    margin: 0;
}

.footnote-backref {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none !important;
    box-shadow: none !important;
}

/* Tables */
.post-full-content table {
    display: inline-block;
    overflow-x: auto;
    margin: 0.5em 0 2.5em;
    max-width: 100%;
    width: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: var(--font-sans-serif);
    font-size: 1.6rem;
    white-space: nowrap;
    vertical-align: top;
}

.post-full-content table {
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center, radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
}

.post-full-content table td:first-child {
    background-image: linear-gradient(to right, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

.post-full-content table td:last-child {
    background-image: linear-gradient(to left, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat;
}

.post-full-content table th {
    color: var(--color-darkgrey);
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    background-color: color-mod(var(--color-wash) l(+4%));
}

.post-full-content table th,
.post-full-content table td {
    padding: 6px 12px;
    border: color-mod(var(--color-wash) l(-1%) s(-5%)) 1px solid;
}


/* 7.1. Post Byline
/* ---------------------------------------------------------- */

.article-byline {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0;
}

.article-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.article-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 12px 0 0;
}

.article-byline-meta {
    color: color-mod(var(--color-midgrey));
    font-size: 1.4rem;
    line-height: 1.2em;
}

.article-byline-meta h4 {
    margin: 0 0 3px;
    font-size: 1.6rem;
}

.article-byline-meta .bull {
    display: inline-block;
    margin: 0 2px;
    opacity: 0.6;
}

.author-avatar {
    display: block;
    overflow: hidden;
    margin: 0 -4px;
    width: 50px;
    height: 50px;
    border: #fff 2px solid;
    border-radius: 100%;
    transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99) 700ms;
}

.basic-info .avatar-wrapper {
    position: relative;
    margin: 0;
    width: 60px;
    height: 60px;
    border: none;
    background: rgba(229, 239, 245, 0.1);
}

.basic-info .avatar-wrapper svg {
    margin: 0;
    width: 60px;
    height: 60px;
    opacity: 0.15;
}

.page-template .article-title {
    margin-bottom: 0;
}


/* 7.3. Subscribe
/* ---------------------------------------------------------- */



/* 7.4. Read more
/* ---------------------------------------------------------- */

.read-more-wrap {
    width: 100%;
    padding: 4vmin;
    margin: 0 auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.read-more {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 4vmin;
}
.read-more .post-card-excerpt p {
    display:none;
}
.read-more .post-card-title {
    margin: 1em 0;
    line-height: 1.4;
}
@media (max-width: 1000px) {
    .read-more {
        grid-template-columns: 1fr 1fr;
    }
    .read-more article:nth-child(3) {
        display: none;
    }
}

@media (max-width: 700px) {
    .read-more {
        grid-template-columns: 1fr;
    }
    .read-more article:nth-child(2) {
        display: none;
    }
}


/* 8. Author Template
/* ---------------------------------------------------------- */

.author-template .posts {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 200px 1fr 1fr;
    grid-gap: 4vmin;
}

.author-template .posts .post-feed {
    grid-column: 2 / 4;
    grid-template-columns: 1fr 1fr;
}

.author-profile {
    padding: 4vmin 0;
}

.author-profile-content {
    height: auto;
    position: sticky;
    top: 100px;
    font-size: 1.6rem;
    line-height:  1.4em;
}
.author-profile-pic {
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 0 0 2rem;
    background: color-mod(var(--color-lightgrey) l(+10%));
    border-radius: 50%;
}

.author-profile h1 {
    margin: 0 0 0.3em;
    font-size: 2rem;
}

.author-profile p {
    margin: 0 0 1.5em;
    color: color-mod(var(--color-midgrey) l(-8%));
}

.author-profile-location {
    margin: 0 0 1.5em;
    font-weight: 700;
}

.author-profile-social-link {
    display: block;
    padding: 0 0 5px;
    font-size: 1.3rem;
    color: color-mod(var(--color-midgrey) l(-8%));
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
}

.author-profile-social-link:hover {
    color: var(--color-darkgrey);
}

@media (max-width: 900px) {
    .author-template .posts .post-feed {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 650px) {
    .author-template .posts {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
    .author-template .posts .post-feed {
        grid-column: 1 / auto;
    }
    .author-profile {
        padding-right: 0;
    }
    .author-profile-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}


/* 8. Tag Template
/* ---------------------------------------------------------- */

.tag-template .post-card-large .post-card-image-link {
    grid-column: 2 / span 2;
    order: 2;
}

.tag-template .post-card-large .post-card-content {
    order: 1;
}


/* 9. Error Template
/* ---------------------------------------------------------- */

.error-content {
    padding: 14vw 4vw 6vw;
}

.error-message {
    padding-bottom: 10vw;
    text-align: center;
}

.error-code {
    margin: 0;
    color: var(--ghost-accent-color);
    font-size: 12vw;
    line-height: 1em;
    letter-spacing: -5px;
}

.error-description {
    margin: 0;
    color: var(--color-midgrey);
    font-size: 3.2rem;
    line-height: 1.3em;
    font-weight: 400;
}

.error-link {
    display: inline-block;
    margin-top: 5px;
}

@media (min-width: 940px) {
    .error-content .post-card {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}

@media (max-width: 800px) {
    .error-content {
        padding-top: 24vw;
    }
    .error-code {
        font-size: 11.2rem;
    }
    .error-message {
        padding-bottom: 16vw;
    }
    .error-description {
        margin: 5px 0 0 0;
        font-size: 1.8rem;
    }
}

@media (max-width: 500px) {
    .error-content {
        padding-top: 28vw;
    }
    .error-message {
        padding-bottom: 14vw;
    }
}


/* 11. Site Footer
/* ---------------------------------------------------------- */

#footer {

}

.site-footer {
    position: relative;
    margin: 6vmin 0 0 0;
    padding: 8vmin 4vmin 0vmin;    
    background-color: var(--color-darkgrey);
    color: var(--color-wash);
}
.site-footer a {
    color:#fff;
}
.site-footer .footer-tags {
    padding-bottom:4vmin;
    border-bottom:1px solid;
}
.site-footer h2 {
    margin-top:0;
    margin-bottom:2.5rem;
    font-family: var(--font-serif);
    font-weight:400;
}
.site-footer .inner {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: auto 1fr;
    font-size: 1.6rem;    
}
.site-footer .inner.no-grid {
    display:block;
}
.site-footer .wrap-footer-notes  {
    padding-top:4vmin;
    padding-bottom:4vmin;
}
.site-footer .logo {
    filter: invert(1);
    max-height:45px;
    margin-right:1rem;
}
.site-footer .copyright a {
    
    letter-spacing: -0.015em;
    font-weight: 500;
}

.site-footer a:hover {
    text-decoration: none;
}

.site-footer-nav ul {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 0 0 0px;
    padding: 0;
    list-style: none;
}

.site-footer-nav li {
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;
    line-height: 2em;
}

.site-footer-nav a {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
}

.site-footer-nav li:not(:first-child) a:before {
    content: "";
    display: block;
    width: 2px;
    height: 2px;
    margin: 0 10px 0 0;
    background: #fff;
    border-radius: 100%;
}

@media (max-width: 800px) {
    .site-footer .inner {
        max-width: 500px;
        grid-template-columns: 1fr;
        grid-gap: 0;
        text-align: center;
    }
    .site-footer .copyright,
    .site-footer .copyright a {
        color: #fff;
        font-size: 1.5rem;
    }
}

/*Progress bar*/
.progress {
    background-color: var(--ghost-accent-color);
    position: sticky;
    top: 0;
    left: 0;
    width: 0%;
    height: 4px;
    z-index: 10000;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
}
#progress {
    opacity:0;
}
#progress.show {
    opacity:1;
}

/*More responsive tweaks*/
@media (max-width: 1366px) {
    .inner {
        padding: 0 30px;
    }
}
@media (min-width: 1024px) {
    
}
@media (max-width: 800px) {
    .site-header-cover {
        -o-object-fit: unset;
        object-fit: unset;
        width:auto;
        max-height:250px;
        text-align: center;
        margin-left: auto;
        margin-right:auto;
    }
    .hero {
        display:block;
    }
    .site-title {
        font-size:1.8em;
    }
    .hero-text {
        max-width:unset;
        width:100%;
        text-align:center;
        padding-top:5rem;
        padding-bottom:3rem;
        padding-left:20px;
        padding-right:20px;
    }
    .post-feed-featured {
        margin-top:5rem;
    }
    .post-feed-featured h6 {
        margin-bottom:2rem;
    }
}

/*

Hey! You reached the end.

 */
